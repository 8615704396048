import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Modal } from 'antd';
import { ButtonView, Plugin } from 'ckeditor5';
import i18next from 'i18next';

import { ModalClassifiers } from '../ModalClassifiers';
import './index.css';

const queryClient = new QueryClient();

export default class ClassifierPlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.model.schema.register('classifier', {
      allowWhere: '$text',
      allowAttributes: ['value', 'label'],
    });

    editor.conversion.for('editingDowncast').elementToElement({
      model: 'classifier',
      view: (modelElement, { writer }) => {
        const classifierValue = modelElement.getAttribute('value');
        const classifierLabel = modelElement.getAttribute('label');

        const spanElement = writer.createContainerElement('span', {
          class: 'classifier',
          'data-classifier': classifierValue,
          'data-label': classifierLabel,
        });

        const textNode = writer.createText(`#{{${classifierLabel}}}`);
        writer.insert(writer.createPositionAt(spanElement, 0), textNode);

        return spanElement;
      },
    });

    editor.conversion.for('dataDowncast').elementToElement({
      model: 'classifier',
      view: (modelElement, { writer }) => {
        return writer.createEmptyElement('span', {
          class: 'classifier',
          'data-classifier': modelElement.getAttribute('value'),
          'data-label': modelElement.getAttribute('label'),
        });
      },
    });

    editor.conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: 'classifier',
      },
      model: (viewElement, { writer }) => {
        return writer.createElement('classifier', {
          value: viewElement.getAttribute('data-classifier'),
          label: viewElement.getAttribute('data-label'),
        });
      },
    });

    editor.ui.componentFactory.add('insertClassifier', (locale) => {
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: 'Add classifier',
        tooltip: true,
        icon: '<?xml version="1.0" encoding="UTF-8"?> <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.39,9.75l-3.75-6.5c-.8-1.39-2.29-2.25-3.9-2.25h-7.5c-1.6,0-3.1,.86-3.9,2.25L.59,9.75c-.8,1.39-.8,3.11,0,4.5l3.75,6.5c.8,1.39,2.3,2.25,3.9,2.25h7.5c1.6,0,3.1-.86,3.9-2.25l3.75-6.5c.8-1.39,.8-3.11,0-4.5Zm-.87,4l-3.75,6.5c-.62,1.08-1.78,1.75-3.03,1.75h-7.5c-1.25,0-2.41-.67-3.03-1.75L1.45,13.75c-.62-1.08-.62-2.42,0-3.5L5.2,3.75c.62-1.08,1.79-1.75,3.03-1.75h7.5c1.25,0,2.41,.67,3.03,1.75l3.75,6.5c.62,1.08,.62,2.42,0,3.5Zm-6.52-.75c-.18,0-.36,.02-.53,.07l-2.08-3.64c.37-.36,.61-.87,.61-1.43,0-1.1-.9-2-2-2s-2,.9-2,2c0,.56,.23,1.07,.61,1.43l-2.08,3.64c-.17-.05-.34-.07-.53-.07-1.1,0-2,.9-2,2s.9,2,2,2c.93,0,1.71-.64,1.94-1.5h4.13c.22,.86,1.01,1.5,1.94,1.5,1.1,0,2-.9,2-2s-.9-2-2-2Zm-4-6c.55,0,1,.45,1,1s-.45,1-1,1-1-.45-1-1,.45-1,1-1Zm-.53,2.93c.17,.05,.34,.07,.53,.07s.36-.02,.53-.07l2.08,3.64c-.26,.25-.45,.57-.54,.93h-4.13c-.09-.36-.28-.68-.54-.93l2.08-3.64Zm-3.47,6.07c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Zm8,0c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Z"/></svg>',
        withText: false,
      });

      buttonView.on('execute', () => {
        const modal = Modal.info({
          title: i18next.t('ModalClassifiers.Title'),
          closable: true,
          icon: null,
          content: (
            <QueryClientProvider client={queryClient}>
              <ModalClassifiers
                handleSelect={(value, label) => {
                  editor.model.change((writer) => {
                    modal.destroy();

                    const classifierHolderElement = writer.createElement('classifier', {
                      value: value,
                      label: label,
                    });

                    editor.model.insertContent(classifierHolderElement);
                  });
                }}
              />
            </QueryClientProvider>
          ),
          okText: i18next.t('ModalClassifiers.Buttons.Close'),
          okType: 'primary',
        });
      });

      return buttonView;
    });
  }
}
