import { BarChartOutlined, FormOutlined, LockOutlined, NumberOutlined, SettingOutlined, TeamOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { routerPaths } from '../../consts';

export const LayoutMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const items = [
    {
      label: t('Menu.Dashboard'),
      key: routerPaths.dashboard,
      icon: <BarChartOutlined />,
    },
    {
      label: t('Menu.Clients'),
      key: routerPaths.clients.index,
      icon: <UserOutlined />,
    },
    {
      label: t('Menu.Templates'),
      key: routerPaths.templates.index,
      icon: <FormOutlined />,
    },
    {
      label: t('Menu.Schemas'),
      key: routerPaths.schemas.index,
      icon: <UnorderedListOutlined />,
    },
    {
      label: t('Menu.Classifications'),
      key: routerPaths.classifiers,
      icon: <NumberOutlined />,
    },
    {
      label: t('Menu.Users'),
      key: routerPaths.users.index,
      icon: <TeamOutlined />,
    },
    {
      label: t('Menu.Permissions'),
      key: routerPaths.permissions,
      icon: <LockOutlined />,
    },
    {
      label: t('Menu.Settings'),
      key: routerPaths.settings,
      icon: <SettingOutlined />,
    },
  ];

  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      mode="vertical"
      items={items}
      onClick={(item) => navigate(item.key)}
      style={{ borderRight: 0 }}
      className="font-semibold"
    />
  );
};
