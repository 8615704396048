import { UserAddOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormUsersPermissions } from '../../components';
import { servicesPermissions, servicesUsers } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesPermissions = () => {
  const { t } = useTranslation();

  const permissions = servicesPermissions.getPermissions();
  const users = servicesUsers.getUsers();

  return (
    <div>
      <div>
        <ContentHeader
          title={t('Permissions.Title')}
          extra={<Button icon={<UserAddOutlined />}>{t('Permissions.Buttons.AddPermissionGroup')}</Button>}
        />

        {users.isFetching || permissions.isFetching ? (
          <Skeleton />
        ) : permissions.data && permissions.data?.length > 0 ? (
          permissions.data.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              className="mb-4"
            >
              <FormUsersPermissions
                data={item}
                users={users.data ?? []}
              />
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};
