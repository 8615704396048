export const configTheme = {
  token: {
    colorBgLayout: '#eaecf1',
  },
  components: {
    Menu: {
      itemMarginInline: 0,
    },
    Table: {
      headerColor: '#aaaaaa',
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Card: {
      paddingLG: 16,
    },
    Form: {
      itemMarginBottom: 16,
    },
    Button: {
      fontWeight: 600,
    },
  },
};
