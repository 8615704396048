import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from 'src/consts';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from 'src/shared';

import { IFormClientRegistrationBCR, initialFormClientRegistrationBCRValues } from './interface';

export const FormClientRegistrationBCR = () => {
  const navigate = useNavigate();

  const handleSubmit = async (data: IFormClientRegistrationBCR) => {
    console.log(data);
    navigate(routerPaths.clients.preview.replace(':id', 'asdasdasasdsad'));
  };

  const validationSchema = z.object({
    business: z.object({
      basic: z.object({
        title: z.string().trim().min(1),
        registrationCountry: z.string().trim().min(1),
        registrationDate: z.string().trim().min(1),
        companyCode: z.string().trim().min(1),
        legalEntity: z.string().trim().min(1),
      }),
      address: z.object({
        country: z.string().trim().min(1),
        municipality: z.string().trim().min(1),
        city: z.string().trim().min(1),
        street: z.string().trim().min(1),
        house: z.string().trim().min(1),
        postCode: z.string().trim().min(1),
      }),
      contacts: z.object({
        email: z.string().email().min(1),
        phone: z.string().trim().min(1),
      }),
    }),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialFormClientRegistrationBCRValues}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        ClientRegistrationBCR.Titles.Basic
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Title"
            name="business.basic.title"
          />
        </Col>
        <Col span={12}>
          <FieldsSelect
            label="ClientRegistrationBCR.Labels.RegistrationCountry"
            name="business.basic.registrationCountry"
          />
        </Col>
        <Col span={12}>
          <FieldsDate
            label="ClientRegistrationBCR.Labels.RegistrationDate"
            name="business.basic.registrationDate"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.CompanyCode"
            name="business.basic.companyCode"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.VATCode"
            name="business.basic.vatCode"
          />
        </Col>
        <Col span={24}>
          <FieldsSelect
            label="ClientRegistrationBCR.Labels.LegalEntity"
            name="business.basic.legalEntity"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        ClientRegistration.Titles.Address
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsSelect
            label="ClientRegistrationBCR.Labels.Country"
            name="business.address.country"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Municipality"
            name="business.address.municipality"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.City"
            name="business.address.city"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Street"
            name="business.address.street"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.House"
            name="business.address.house"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Flat"
            name="business.address.flat"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.PostCode"
            name="business.address.postCode"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        ClientRegistration.Titles.Contacts
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Email"
            name="business.contacts.email"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Phone"
            name="business.contacts.phone"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationBCR.Labels.Website"
            name="business.contacts.website"
          />
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          icon={<SearchOutlined />}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Flex>
    </Form>
  );
};
