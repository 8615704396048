import { EnumClientTypes } from '../../enums';

export interface IFormSchemaSearch {
  searchTerm: string;
  type: string;
}

export const initialFormSchemaSearchValues: IFormSchemaSearch = {
  searchTerm: '',
  type: EnumClientTypes.ALL,
};
