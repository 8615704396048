import { UserAddOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CardUser } from '../../components';
import { routerPaths } from '../../consts';
import { servicesUsers } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesUsers = () => {
  const { t } = useTranslation();
  const { data, isFetching } = servicesUsers.getUsers();
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(routerPaths.users.preview.replace(':id', id));
  };

  return (
    <div>
      <ContentHeader
        title={t('Users.Title')}
        extra={<Button icon={<UserAddOutlined />}>{t('Users.Buttons.AddNew')}</Button>}
      />

      {isFetching ? (
        <Skeleton />
      ) : data && data?.length > 0 ? (
        data.map((item, index) => (
          <CardUser
            key={index}
            data={item}
            onClick={handleClick}
          />
        ))
      ) : (
        <Empty />
      )}
    </div>
  );
};
