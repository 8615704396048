export const mockClassifiers = [
  {
    value: 'private',
    label: 'Private',
    readOnly: true,
    groups: [
      {
        value: 'basic',
        label: 'Basic information',
        readOnly: true,
        classifiers: [
          {
            value: 'name',
            label: 'Name',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'surname',
            label: 'Surname',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'personCode',
            label: 'PersonCode',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'citizenship',
            label: 'Citizenship',
            type: 'select',
            typeOptions: 'countries',
            readOnly: true,
          },
          {
            value: 'birthday',
            label: 'Birthday',
            type: 'date',
            readOnly: true,
          },
        ],
      },
      {
        value: 'address',
        label: 'Address',
        readOnly: true,
        classifiers: [
          {
            value: 'country',
            label: 'Country',
            type: 'select',
            typeOptions: 'countries',
            readOnly: true,
          },
          {
            value: 'municipality',
            label: 'Municipality',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'city',
            label: 'City',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'street',
            label: 'Street',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'house',
            label: 'House',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'flat',
            label: 'Flat',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'postCode',
            label: 'PostCode',
            type: 'string',
            readOnly: true,
          },
        ],
      },
      {
        value: 'contacts',
        label: 'Contacts',
        readOnly: true,
        classifiers: [
          {
            value: 'email',
            label: 'Email',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'phone',
            label: 'Phone',
            type: 'string',
            readOnly: true,
          },
        ],
      },
    ],
  },
  {
    value: 'business',
    label: 'Business',
    groups: [
      {
        value: 'basic',
        label: 'Basic',
        readOnly: true,
        classifiers: [
          {
            value: 'title',
            label: 'Title',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'registrationCountry',
            label: 'RegistrationCountry',
            type: 'select',
            typeOptions: 'countries',
            readOnly: true,
          },
          {
            value: 'registrationDate',
            label: 'RegistrationDate',
            type: 'date',
            readOnly: true,
          },
          {
            value: 'companyCode',
            label: 'CompanyCode',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'vatCode',
            label: 'VATCode',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'legalEntity',
            label: 'LegalEntity',
            type: 'select',
            typeOptions: 'legalEntities',
            readOnly: true,
          },
        ],
      },
      {
        value: 'address',
        label: 'Address',
        readOnly: true,
        classifiers: [
          {
            value: 'country',
            label: 'Country',
            type: 'select',
            typeOptions: 'countries',
            readOnly: true,
          },
          {
            value: 'municipality',
            label: 'Municipality',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'city',
            label: 'City',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'street',
            label: 'Street',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'house',
            label: 'House',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'flat',
            label: 'Flat',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'postCode',
            label: 'PostCode',
            type: 'string',
            readOnly: true,
          },
        ],
      },
      {
        value: 'contacts',
        label: 'Contacts',
        readOnly: true,
        classifiers: [
          {
            value: 'email',
            label: 'Email',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'phone',
            label: 'Phone',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'website',
            label: 'Website',
            type: 'string',
            readOnly: true,
          },
        ],
      },
    ],
  },
  {
    value: 'documents',
    label: 'Documents',
    groups: [
      {
        value: 'private',
        label: 'Private',
        readOnly: true,
        classifiers: [
          {
            value: 'passport',
            label: 'Passport',
            type: 'file',
            readOnly: true,
          },
          {
            value: 'idCard',
            label: 'ID-Card',
            type: 'file',
            readOnly: true,
          },
        ],
      },
      {
        value: 'business',
        label: 'Business',
        readOnly: true,
        classifiers: [
          {
            value: 'license',
            label: 'License',
            type: 'string',
            readOnly: true,
          },
          {
            value: 'insurance',
            label: 'Insurance',
            type: 'string',
            readOnly: true,
          },
        ],
      },
    ],
  },
];
