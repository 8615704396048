import { Gutter } from 'antd/es/grid/row';

export const styles = {
  gutter: {
    default: [16, 0] as [Gutter, Gutter],
    small: [8, 0] as [Gutter, Gutter],
  },
  tag: {
    success: '#3f9c19',
    error: '#ff4400',
    warning: '#ff8a00',
  },
};
