import { EnumClientTypes } from '../../enums';

export interface IFormTemplateSearch {
  searchTerm: string;
  type: string;
}

export const initialFormTemplateSearchValues: IFormTemplateSearch = {
  searchTerm: '',
  type: EnumClientTypes.ALL,
};
