import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { styles } from '../../consts';
import { IApiUsersGet } from '../../services';

interface IProps {
  data: IApiUsersGet;
  onClick: (id: string) => void;
}

export const CardUser = ({ data, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <Card
      className="mb-4"
      onClick={() => onClick(data.id)}
      hoverable={true}
    >
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <Typography.Text className="block">
            {data.name} {data.surname}
          </Typography.Text>
          <Typography.Text type="secondary">{data.email}</Typography.Text>
          <Typography.Text className="block"></Typography.Text>
        </Col>
        <Col
          flex="none"
          className="text-right"
        >
          <Typography.Text
            type="secondary"
            className="block"
          >
            {t('CardUsers.Labels.RegistrationDate')}
            <Typography.Text>{data.registrationDate}</Typography.Text>
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className="block"
          >
            {t('CardUsers.Labels.GroupsAssigned')} <Typography.Text>{data.groupsAssigned.length ? data.groupsAssigned.join(', ') : '-'}</Typography.Text>
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};
