import { DatePicker, DatePickerProps } from 'antd';
import { useController } from 'react-hook-form';
import type { FormItemLayout } from 'antd/es/form/Form';
import dayjs from 'dayjs';

import { FieldsLabel } from '../Label.tsx';
import { FieldsError } from '../Error.tsx';
import { getNestedObject } from '../../../helpers';

interface IPros extends DatePickerProps {
  name: string;
  label?: string;
  layout?: FormItemLayout;
}

export const FieldsDate = ({
  name,
  label,
  layout = 'vertical',
  ...restProps
}: IPros) => {
  const { field, formState } = useController({
    name: name,
    shouldUnregister: true,
  });

  const error: any = getNestedObject(formState.errors, name);

  return (
    <div className="mb-4">
      <FieldsLabel label={label} />
      <DatePicker
        status={error !== undefined ? 'error' : ''}
        className="w-full"
        onChange={(_value, valueString) => field.onChange(valueString)}
        value={field.value ? dayjs(field.value) : undefined}
        {...restProps}
      />
      <FieldsError error={error?.message} />
    </div>
  );
};
