import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { routerPaths } from '../../consts';

interface IParams {
  [key: string]: string | undefined;
}

export const PagesClientsPreview = () => {
  const navigate = useNavigate();
  const { id }: IParams = useParams();

  return (
    <div>
      <Button onClick={() => navigate(routerPaths.clients.index)}>Back</Button>
      PagesClients <Button onClick={() => navigate(routerPaths.clients.preview.replace(':id', id!))}>Edit</Button>
    </div>
  );
};
