export interface IFormClientRegistrationBCR {
  business: {
    basic: {
      title: string;
      registrationCountry: string;
      registrationDate: string;
      companyCode?: string;
      vatCode?: string;
      legalEntity?: string;
    };
    address: {
      country: string;
      municipality: string;
      city: string;
      street: string;
      house: string;
      flat?: string;
      postCode: string;
    };
    contacts: {
      email: string;
      phone: string;
      website?: string;
    };
  };
}

export const initialFormClientRegistrationBCRValues: IFormClientRegistrationBCR = {
  business: {
    basic: {
      title: '',
      registrationCountry: '',
      registrationDate: '',
      companyCode: '',
      vatCode: '',
      legalEntity: '',
    },
    address: {
      country: '',
      municipality: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      postCode: '',
    },
    contacts: {
      email: '',
      phone: '',
      website: '',
    },
  },
};
