import { DownOutlined, FolderOutlined } from '@ant-design/icons';
import { Input, Tree, TreeDataNode } from 'antd';
import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { servicesClassifiers } from '../../services';

interface ExtendedTreeDataNode extends TreeDataNode {
  label?: string;
}

interface IProps {
  handleSelect: (label: Key, value: string) => void;
}

export const ModalClassifiers = ({ handleSelect }: IProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [treeData, setTreeData] = useState<ExtendedTreeDataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { data, isFetched } = servicesClassifiers.getClassifiers();
  const { t } = useTranslation();

  const treeDataClassifiers = useMemo(() => {
    return (
      data?.map((entity) => ({
        title: entity.label,
        key: entity.value,
        selectable: false,
        icon: <FolderOutlined />,
        children: entity.groups.map((group) => ({
          title: group.label,
          key: `${entity.value}.${group.value}`,
          selectable: false,
          children: group.classifiers.map((classifier) => ({
            title: classifier.label,
            label: `${entity.label} > ${group.label} > ${classifier.label}`, // Added label for easy access
            key: `${entity.value}.${group.value}.${classifier.value}`,
          })),
        })),
      })) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (isFetched) {
      setTreeData(treeDataClassifiers);
    }
  }, [isFetched, treeDataClassifiers]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      if (!value) {
        setTreeData(treeDataClassifiers);
        setExpandedKeys([]);
        return;
      }

      const filteredData = treeDataClassifiers
        .map((group) => ({
          ...group,
          children: group.children
            .map((subGroup) => ({
              ...subGroup,
              children: subGroup.children.filter((classifier) => {
                const match = classifier.title.toLowerCase().includes(value.toLowerCase());
                if (match) setExpandedKeys((prev) => [...prev, subGroup.key]);
                return match;
              }),
            }))
            .filter((subGroup) => subGroup.children.length > 0),
        }))
        .filter((group) => group.children.length > 0);

      setTreeData(filteredData ?? []);
    },
    [treeDataClassifiers],
  );

  return (
    <div>
      <div className="mb-4">
        <Input.Search
          placeholder={t('ModalClassifiers.Labels.Placeholder')}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          allowClear
        />
      </div>
      <Tree
        showIcon
        showLine
        switcherIcon={<DownOutlined />}
        treeData={treeData}
        expandedKeys={expandedKeys}
        autoExpandParent
        onExpand={(keys) => setExpandedKeys(keys as string[])}
        onSelect={(_, { node }: { node: ExtendedTreeDataNode }) => {
          const label = node.label || '';

          handleSelect(node.key, label);
        }}
      />
    </div>
  );
};
