import { BranchesOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CardSchema } from '../../components';
import { FormSchemaSearch } from '../../components/FormSchemaSearch';
import { routerPaths } from '../../consts';
import { servicesSchemas } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesSchemas = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isFetching } = servicesSchemas.getSchemas();

  const handleClick = (id: string) => {
    navigate(routerPaths.schemas.preview.replace(':id', id));
  };
  return (
    <div>
      <ContentHeader
        title={t('Schemas.Title')}
        extra={
          <Button
            icon={<BranchesOutlined />}
            onClick={() => navigate(routerPaths.schemas.create)}
          >
            {t('SchemaCreate.Buttons.CreateNew')}
          </Button>
        }
      />
      <FormSchemaSearch />
      {!isFetching ? (
        data?.map((item, index) => (
          <div
            className="mb-4"
            key={index}
          >
            <CardSchema
              data={item}
              onClick={handleClick}
            />
          </div>
        ))
      ) : (
        <Skeleton loading={true} />
      )}
    </div>
  );
};
