import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';

import { styles } from 'src/consts';
import { IApiTemplatesGet } from 'src/services';

interface IProps {
  data: IApiTemplatesGet;
  onClick: (id: string) => void;
}

export const CardTemplate = ({ data, onClick }: IProps) => {
  return (
    <Card
      onClick={() => onClick(data.id)}
      hoverable={true}
    >
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <Typography.Title level={5}>{data.title}</Typography.Title>
          <Typography.Text type="secondary">{data.description}</Typography.Text>
          <Typography.Text className="block">
            <Typography.Text type="secondary">Author</Typography.Text> {data.author}
          </Typography.Text>
        </Col>
        <Col
          flex="none"
          className="text-right"
        >
          <Typography.Text
            type="secondary"
            className="block"
          >
            Version: <Typography.Text>{data.version}</Typography.Text>
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className="block"
          >
            DateCreated: <Typography.Text>{data.dateCreated}</Typography.Text>
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className="block"
          >
            DateUpdated: <Typography.Text>{data.dateUpdated}</Typography.Text>
          </Typography.Text>
        </Col>
        <Col flex="none">
          <Button
            type="text"
            size="large"
            icon={<MoreOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
        </Col>
      </Row>
    </Card>
  );
};
