import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Empty, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { IApiClassifiersGet } from '../../services';

interface IProps {
  data: IApiClassifiersGet;
}

export const CardClassifiers = ({ data }: IProps) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Type options',
      dataIndex: 'typeOptions',
    },
  ];

  return (
    <Card
      title={data.label}
      className="mb-4"
    >
      {data.groups.length > 0 ? (
        <Collapse
          className="mb-4"
          items={data.groups.map((group) => ({
            key: group.value,
            label: group.label,
            extra: (
              <Button
                type="link"
                size="small"
                icon={<PlusOutlined />}
              >
                {t('Classifiers.Buttons.AddClassifier')}
              </Button>
            ),
            children: (
              <Table
                rowKey="value"
                tableLayout="fixed"
                size="small"
                bordered={true}
                columns={columns}
                dataSource={group.classifiers}
                pagination={false}
              />
            ),
          }))}
        />
      ) : (
        <Empty />
      )}
      <Button
        type="link"
        icon={<PlusOutlined />}
      >
        {t('Classifiers.Buttons.AddEntityGroup')} {data.label}
      </Button>
    </Card>
  );
};
