import { Input, InputProps } from 'antd';
import { useController } from 'react-hook-form';
import type { FormItemLayout } from 'antd/es/form/Form';

import { getNestedObject } from '../../../helpers';
import { FieldsLabel } from '../Label.tsx';
import { FieldsError } from '../Error.tsx';

interface IPros extends InputProps {
  name: string;
  label?: string;
  layout?: FormItemLayout;
}

export const FieldsInput = ({
  name,
  label,
  layout = 'vertical',
  ...restProps
}: IPros) => {
  const { field, formState } = useController({
    name: name,
    shouldUnregister: true,
  });

  const error: any = getNestedObject(formState.errors, name);

  return (
    <div className="mb-4">
      <FieldsLabel label={label} />
      <Input
        ref={field.ref}
        onChange={(e) => field.onChange(e.target.value)}
        value={field.value}
        status={error !== undefined ? 'error' : ''}
        {...restProps}
      />
      <FieldsError error={error?.message} />
    </div>
  );
};
