import { ConfigProvider } from 'antd';
import 'antd/es/style/reset.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App.tsx';
import { configTheme } from './configs';
import './i18n/config.ts';
import './styles/main.css';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter future={{ v7_startTransition: true }}>
      <ConfigProvider theme={configTheme}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </StrictMode>,
);
