import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { styles } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { FieldsInput, FieldsSelect, Form } from '../../shared';
import { IFormClientSearch, initialFormClientSearchValues } from './interface';

const formValidationSchema = z.object({
  type: z.string().optional(),
  searchTerm: z.string().optional(),
});

export const FormClientSearch = () => {
  const handleSubmit = async (data: IFormClientSearch) => {
    console.log(data);
  };
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormClientSearchValues}
      validationSchema={formValidationSchema}
    >
      <Row
        gutter={styles.gutter.default}
        align="bottom"
      >
        <Col flex="auto">
          <Row gutter={styles.gutter.default}>
            <Col span={12}>
              <FieldsSelect
                label={t('ClientSearch.Labels.ClientType')}
                name="type"
                options={[
                  {
                    value: EnumClientTypes.ALL,
                    label: t('Enums.ClientTypes.ALL'),
                  },
                  {
                    value: EnumClientTypes.PCR,
                    label: t('Enums.ClientTypes.PCR'),
                  },
                  {
                    value: EnumClientTypes.BCR,
                    label: t('Enums.ClientTypes.BCR'),
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <FieldsInput
                label={t('ClientSearch.Labels.SearchTerm')}
                name="searchTerm"
              />
            </Col>
          </Row>
        </Col>
        <Col flex="none">
          <Button
            icon={<SearchOutlined />}
            type="primary"
            htmlType="submit"
            className="mb-4"
          >
            {t('ClientSearch.Buttons.Submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
