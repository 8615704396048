import { useQuery } from '@tanstack/react-query';

import { mockClients } from '../../_mocks';
import { apiPaths, env } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiClientsGet } from './intefaces';

const useGetClients = () => {
  return useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      if (env.isTest) {
        return mockClients as IApiClientsGet[];
      }

      const res = await helpersAxios(apiPaths.clients.getClients);

      return res.data as IApiClientsGet[];
    },
  });
};

export const servicesClients = {
  getClients: useGetClients,
};
