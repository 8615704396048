import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './translations/en.json';
import translationLt from './translations/lt.json';

const currentLanguage: string = window.sessionStorage.getItem('language') ?? import.meta.env.VITE_DEFAULT_LANGUAGE;

void i18next.use(initReactI18next).init(
  {
    lng: currentLanguage,
    resources: {
      en: {
        translation: translationEn,
      },
      lt: {
        translation: translationLt,
      },
    },
    fallbackLng: 'en',
    react: {
      useSuspense: true,
    },
  },
  (error) => {
    if (error) {
      console.error('something went wrong loading translation', error);
    }
  },
);
