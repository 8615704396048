import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ContentHeader } from '../../shared';

export const PagesUsersPreview = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeader
        title={t('Users.Title')}
        extra={<Button icon={<UserAddOutlined />}>AddNewUser</Button>}
      />
    </div>
  );
};
