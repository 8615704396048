import { Button, Col, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import { LeftOutlined } from '@ant-design/icons';

interface IProps {
  title: string;
  onBack?: () => void;
  extra?: ReactNode;
}

export const ContentHeader = ({ title, onBack, extra }: IProps) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <Row align="middle">
        {typeof onBack === 'function' && (
          <Col flex="none">
            <Button
              size="large"
              icon={<LeftOutlined />}
              type="text"
              onClick={onBack}
            />
          </Col>
        )}
        <Col flex="auto">
          <Typography.Title level={3} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Col>
        <Col flex="none">{extra}</Col>
      </Row>
    </div>
  );
};
