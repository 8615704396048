import { useQuery } from '@tanstack/react-query';

import { mockClassifiers } from '../../_mocks';
import { apiPaths, env } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiClassifiersGet } from './intefaces';

const useGetClassifiers = () => {
  return useQuery({
    queryKey: ['getClassifiers'],
    queryFn: async () => {
      if (env.isTest) {
        return mockClassifiers as IApiClassifiersGet[];
      }

      const res = await helpersAxios(apiPaths.classifiers.getClassifiers);

      return res.data as IApiClassifiersGet[];
    },
  });
};

export const servicesClassifiers = {
  getClassifiers: useGetClassifiers,
};
