import { MoreOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { styles } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { IApiClientsGet } from '../../services';

interface IProps {
  data: IApiClientsGet;
  onClick: (id: string) => void;
}

export const CardClient = ({ data, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <Card
      onClick={() => onClick(data.id)}
      hoverable={true}
    >
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <Typography.Title level={5}>
            {data.type === EnumClientTypes.PCR ? `${data.name} ${data.surname}` : data.title} &#183; {data.type}
          </Typography.Title>
          <Typography.Text type="secondary">{data.address}</Typography.Text>
        </Col>
        <Col
          flex="none"
          className="text-right"
        >
          <Typography.Text
            type="secondary"
            className="block"
          >
            {t('CardClient.Labels.Projects')} <Typography.Text strong={true}>{data.projects}</Typography.Text>
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className="block"
          >
            {t('CardClient.Labels.ProjectsActive')} <Typography.Text strong={true}>{data.projectsActive}</Typography.Text>
          </Typography.Text>
        </Col>
        <Col flex="none">
          <Button
            type="text"
            size="large"
            icon={<MoreOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
        </Col>
      </Row>
      <Divider className="my-3" />
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <Tag
            color={styles.tag.success}
            bordered={false}
          >
            Active
          </Tag>
        </Col>
        <Col>
          <Typography.Text type="secondary">
            {t('CardClient.Labels.RegistrationDate')} {data.registrationDate}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};
