import { useQuery } from '@tanstack/react-query';

import { mockSchemas } from '../../_mocks';
import { apiPaths, env } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiSchemasGet } from './intefaces';

const useGetSchemas = () => {
  return useQuery({
    queryKey: ['useGetSchemas'],
    queryFn: async () => {
      if (env.isTest) {
        return mockSchemas as IApiSchemasGet[];
      }

      const res = await helpersAxios(apiPaths.schemas.getSchemas);

      return res.data as IApiSchemasGet[];
    },
  });
};

export const servicesSchemas = {
  getSchemas: useGetSchemas,
};
