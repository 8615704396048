import { Card, Radio, RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormClientRegistrationBCR, FormClientRegistrationPCR } from '../../components';
import { routerPaths } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { ContentHeader } from '../../shared';

export const PagesClientsCreate = () => {
  const [formType, setFormType] = useState(EnumClientTypes.PCR);
  const navigate = useNavigate();

  const handleChangeForm = (e: RadioChangeEvent) => {
    setFormType(e.target.value);
  };

  return (
    <div>
      <ContentHeader
        title="ClientsRegistration.Title"
        onBack={() => navigate(routerPaths.clients.index)}
      />
      <div className="max-w-screen-sm mx-auto mb-4">
        <Radio.Group
          onChange={handleChangeForm}
          value={formType}
          className="w-full"
          size="large"
        >
          <Radio.Button
            className="w-1/2 text-center"
            value={EnumClientTypes.PCR}
          >
            ClientRegistration.PCR
          </Radio.Button>
          <Radio.Button
            className="w-1/2 text-center"
            value={EnumClientTypes.BCR}
          >
            ClientRegistration.BCR
          </Radio.Button>
        </Radio.Group>
      </div>
      <Card className="max-w-screen-sm mx-auto">
        {formType === EnumClientTypes.PCR && <FormClientRegistrationPCR />}
        {formType === EnumClientTypes.BCR && <FormClientRegistrationBCR />}
      </Card>
    </div>
  );
};
