import { useNavigate } from 'react-router-dom';

import { routerPaths } from '../../consts';
import { ContentHeader } from '../../shared';

export const PagesSchemasPreview = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ContentHeader
        title="SchemasPreview.Title"
        onBack={() => navigate(routerPaths.schemas.index)}
      />
    </div>
  );
};
