import { Typography } from 'antd';

interface IProps {
  error?: string;
}

export const FieldsError = ({ error }: IProps) => {
  if (typeof error !== 'string') {
    return null;
  }

  return (
    <div className="mt-1">
      <Typography.Text type="danger">{error}</Typography.Text>
    </div>
  );
};
