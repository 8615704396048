import { useQuery } from '@tanstack/react-query';

import { mockTemplates } from '../../_mocks';
import { apiPaths, env } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiTemplatesGet } from './intefaces';

const useGetTemplates = () => {
  return useQuery({
    queryKey: ['useGetTemplates'],
    queryFn: async () => {
      if (env.isTest) {
        return mockTemplates as IApiTemplatesGet[];
      }

      const res = await helpersAxios(apiPaths.templates.getTemplates);

      return res.data as IApiTemplatesGet[];
    },
  });
};

export const servicesTemplates = {
  getTemplates: useGetTemplates,
};
