import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from '../../consts';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from '../../shared';
import { IFormClientRegistrationPCR, initialFormClientRegistrationPCRValues } from './interface.ts';

export const FormClientRegistrationPCR = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (data: IFormClientRegistrationPCR) => {
    console.log(data);
    navigate(routerPaths.clients.preview.replace(':id', 'asdasdsasad123'));
  };

  const validationSchema = z.object({
    private: z.object({
      basic: z.object({
        name: z.string().trim().min(1, { message: 'Required' }),
        surname: z.string().trim().min(1),
        birthday: z.string().trim().min(1, { message: 'Select date' }),
        personCode: z.string().trim().min(1),
        citizenship: z.string().trim().min(1),
      }),
      address: z.object({
        country: z.string().trim().min(1),
        municipality: z.string().trim().min(1),
        city: z.string().trim().min(1),
        street: z.string().trim().min(1),
        house: z.string().trim().min(1),
        postCode: z.string().trim().min(1),
      }),
      contacts: z.object({
        email: z.string().email().min(1),
        phone: z.string().trim().min(1),
      }),
    }),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialFormClientRegistrationPCRValues}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationPCR.Titles.Basic')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Name"
            name="private.basic.name"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Surname"
            name="private.basic.surname"
          />
        </Col>
        <Col span={12}>
          <FieldsDate
            label="ClientRegistrationPCR.Labels.Birthday"
            name="private.basic.birthday"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.PersonCode"
            name="private.basic.personCode"
          />
        </Col>
        <Col span={24}>
          <FieldsSelect
            label="ClientRegistrationPCR.Labels.Citizenship"
            name="private.basic.citizenship"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        ClientRegistration.Titles.Address
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsSelect
            label="ClientRegistrationPCR.Labels.Country"
            name="private.address.country"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Municipality"
            name="private.address.municipality"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.City"
            name="private.address.city"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Street"
            name="private.address.street"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.House"
            name="private.address.house"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Flat"
            name="private.address.flat"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.PostCode"
            name="private.address.postCode"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        ClientRegistration.Titles.Contacts
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Email"
            name="private.contacts.email"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label="ClientRegistrationPCR.Labels.Phone"
            name="private.contacts.phone"
          />
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          icon={<SearchOutlined />}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Flex>
    </Form>
  );
};
