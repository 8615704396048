import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormSchemaCreate } from '../../components';
import { routerPaths } from '../../consts';
import { ContentHeader } from '../../shared';

export const PagesSchemasCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeader
        title={t('SchemaCreate.Title')}
        onBack={() => navigate(routerPaths.schemas.index)}
      />
      <Card className="max-w-screen-sm mx-auto">
        <FormSchemaCreate />
      </Card>
    </div>
  );
};
