import logo from '../../assets/logo.svg';
import logoSmall from '../../assets/logoSmall.svg';

interface IProps {
  collapsed: boolean;
}

export const LayoutLogo = ({ collapsed }: IProps) => {
  return (
    <div style={{ marginBottom: 16, textAlign: 'center' }}>
      <img
        style={{ maxWidth: '120px' }}
        src={collapsed ? logoSmall : logo}
        alt={import.meta.env.VITE_TITLE}
      />
    </div>
  );
};
