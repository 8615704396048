export interface IFormClientRegistrationPCR {
  personal: {
    basic: {
      firstName: string;
      lastName: string;
      personalCode: string;
      birthdate: string;
    };
    address: {
      country: string;
      municipality: string;
      city: string;
      street: string;
      house: string;
      flat?: string;
      postCode: string;
    };
    contacts: {
      email: string;
      phone: string;
      website?: string;
    };
  };
}

export const initialFormClientRegistrationPCRValues: IFormClientRegistrationPCR = {
  personal: {
    basic: {
      firstName: '',
      lastName: '',
      personalCode: '',
      birthdate: '',
    },
    address: {
      country: '',
      municipality: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      postCode: '',
    },
    contacts: {
      email: '',
      phone: '',
      website: '',
    },
  },
};
