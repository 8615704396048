import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { CardClassifiers } from '../../components';
import { servicesClassifiers } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesClassifiers = () => {
  const { data, isFetching } = servicesClassifiers.getClassifiers();
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeader
        title={t('Classifiers.Title')}
        extra={<Button icon={<PlusOutlined />}>{t('Classifiers.Buttons.AddEntity')}</Button>}
      />
      {isFetching ? (
        <Skeleton />
      ) : data && data.length > 0 ? (
        data.map((entity, index) => (
          <CardClassifiers
            data={entity}
            key={index}
          />
        ))
      ) : (
        <Empty />
      )}
    </div>
  );
};
