export const routerPaths = {
  login: '/',
  dashboard: '/',
  clients: {
    index: '/clients',
    create: '/clients/create',
    preview: '/clients/preview/:id',
  },
  templates: {
    index: '/templates',
    create: '/templates/create',
    preview: '/templates/preview/:id',
  },
  classifiers: '/classifiers',
  users: {
    index: '/users',
    preview: '/users/preview/:id',
  },
  settings: '/settings',
  schemas: {
    index: '/schemas',
    create: '/schemas/create',
    preview: '/schemas/preview/:id',
  },
  permissions: '/permissions',
};
