import { useQuery } from '@tanstack/react-query';

import { mockPermissions } from '../../_mocks';
import { apiPaths, env } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiPermissionsGet } from './intefaces';

const useGetPermissions = () => {
  return useQuery({
    queryKey: ['getPermissions'],
    queryFn: async () => {
      if (env.isTest) {
        return mockPermissions as IApiPermissionsGet[];
      }

      const res = await helpersAxios(apiPaths.permissions.getPermissions);

      return res.data as IApiPermissionsGet[];
    },
  });
};

export const servicesPermissions = {
  getPermissions: useGetPermissions,
};
