import { UserAddOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CardClient, FormClientSearch } from '../../components';
import { routerPaths } from '../../consts';
import { servicesClients } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesClients = () => {
  const navigate = useNavigate();
  const { data, isFetching } = servicesClients.getClients();
  const { t } = useTranslation();

  const handleClick = (id: string) => {
    navigate(routerPaths.clients.preview.replace(':id', id));
  };

  return (
    <div>
      <ContentHeader
        title={t('Clients.Title')}
        extra={
          <Button
            icon={<UserAddOutlined />}
            onClick={() => navigate(routerPaths.clients.create)}
          >
            {t('Clients.Buttons.CreateNew')}
          </Button>
        }
      />
      <FormClientSearch />
      {!isFetching ? (
        data?.map((item, index) => (
          <div
            className="mb-4"
            key={index}
          >
            <CardClient
              data={item}
              onClick={handleClick}
            />
          </div>
        ))
      ) : (
        <Skeleton loading={true} />
      )}
    </div>
  );
};
