import { BellOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Layout, Row, theme } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { styles } from '../../consts';
import { LayoutUser } from '../LayoutUser';

interface IProps {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const LayoutHeader = ({ collapsed, setCollapsed }: IProps) => {
  const { token } = theme.useToken();

  return (
    <Layout.Header style={{ padding: '0 16px', backgroundColor: token.colorBgLayout }}>
      <Row
        gutter={styles.gutter.default}
        align="middle"
      >
        <Col flex="auto">
          <Button
            size="large"
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed((prevState) => !prevState)}
          />
        </Col>
        <Col
          flex="none"
          className="leading-4"
        >
          <Badge
            count={3}
            offset={[-5, 5]}
          >
            <Button
              size="large"
              type="text"
              icon={<BellOutlined />}
            />
          </Badge>
        </Col>
        <Col
          flex="none"
          className="leading-4"
        >
          <LayoutUser />
        </Col>
        <Col
          flex="none"
          className="leading-4"
        >
          <Button
            size="large"
            type="text"
            icon={<LogoutOutlined />}
          />
        </Col>
      </Row>
    </Layout.Header>
  );
};
