import { useTranslation } from 'react-i18next';

import { ContentHeader } from '../../shared';

export const PagesSettings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeader title={t('Settings.Title')} />
    </div>
  );
};
