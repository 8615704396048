import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { LayoutAuthMain } from './layouts';
import { RoutesAuth, RoutesPublic } from './routes';

function App() {
  const isLoggedIn = true;

  const queryClient = new QueryClient();

  if (isLoggedIn) {
    return (
      <QueryClientProvider client={queryClient}>
        <LayoutAuthMain>
          <RoutesAuth />
        </LayoutAuthMain>
      </QueryClientProvider>
    );
  }

  return <RoutesPublic />;
}

export default App;
