export const mockPermissions = [
  {
    title: 'Managers',
    users: ['1'],
    groups: [
      {
        name: 'Clients',
        permissions: [
          {
            label: 'Create',
            name: 'create',
            value: false,
          },
          {
            label: 'Preview',
            name: 'preview',
            value: true,
          },
          {
            label: 'Delete',
            name: 'delete',
            value: true,
          },
        ],
      },
      {
        name: 'Schema',
        permissions: [
          {
            label: 'Create',
            name: 'create',
            value: true,
          },
          {
            label: 'Preview',
            name: 'preview',
            value: true,
          },
          {
            label: 'Delete',
            name: 'delete',
            value: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Viewers',
    users: ['2', '1'],
    groups: [
      {
        name: 'Clients',
        permissions: [
          {
            label: 'Create',
            name: 'create',
            value: false,
          },
          {
            label: 'Preview',
            name: 'preview',
            value: true,
          },
          {
            label: 'Delete',
            name: 'delete',
            value: false,
          },
        ],
      },
      {
        name: 'Schema',
        permissions: [
          {
            label: 'Create',
            name: 'create',
            value: false,
          },
          {
            label: 'Preview',
            name: 'preview',
            value: true,
          },
          {
            label: 'Delete',
            name: 'delete',
            value: false,
          },
        ],
      },
    ],
  },
];
