export const mockUsers = [
  {
    id: '1',
    name: 'Marius',
    surname: 'Bendorius',
    email: 'mariusbendroius@gmail.com',
    groupsAssigned: ['Administrators', 'Moderators'],
    registrationDate: '2004-12-01',
  },
  {
    id: '2',
    name: 'Maksim',
    surname: 'Slotvinskij',
    email: 'm.slotvinskij@gmail.com',
    groupsAssigned: ['Moderators'],
    registrationDate: '2014-11-10',
  },
  {
    id: '3',
    name: 'Petriukas',
    surname: 'Petrauskiukas',
    email: 'petriukas@matavi.eu',
    groupsAssigned: [],
    registrationDate: '2000-01-13',
  },
];
