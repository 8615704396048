import { Avatar, Space, Typography } from 'antd';

export const LayoutUser = () => {
  return (
    <Space
      align="center"
      className=" leading-4"
    >
      <Avatar>M</Avatar>
      <div>
        <Typography.Text
          className="block leading-4"
          strong={true}
        >
          Marius Bendorius
        </Typography.Text>
        <Typography.Text
          className="block leading-4"
          type="secondary"
        >
          marius.bendorius@gmail.com
        </Typography.Text>
      </div>
    </Space>
  );
};
