export const mockSchemas = [
  {
    id: 'bb084f6d-5e60-4aeb-b2dd-2e8f8f3d876a',
    title: 'Koepp and Sons',
    version: 83,
    documentsCount: 20,
    dateCreated: '2020-11-24T07:32:48.024Z',
    dateUpdated: '2024-09-06T04:27:49.245Z',
    description: 'Carus via caute pax nulla talis theologus tabella. Demo cotidie aeneus venio ab sulum. Deleniti desolo corporis.',
    author: 'Monty0',
  },
  {
    id: 'bf3c5197-90f6-4f0e-b3e6-4d24b1c266fa',
    title: 'Lynch - Rosenbaum',
    version: 1,
    documentsCount: 10,
    dateCreated: '2020-11-20T20:56:58.381Z',
    dateUpdated: '2022-03-21T03:37:13.070Z',
    description: 'Cohors vulariter aetas sulum curso. Vero triduana spoliatio decens verbera. Tricesimus vomer textus commodi theatrum aggero decerno.',
    author: 'Alverta78',
  },
];
