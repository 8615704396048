import { Outlet, Route, Routes } from 'react-router-dom';

import { routerPaths } from '../../consts';
import {
  PagesClassifiers,
  PagesClients,
  PagesClientsCreate,
  PagesClientsPreview,
  PagesDashboard,
  PagesPermissions,
  PagesSchemas,
  PagesSchemasCreate,
  PagesSchemasPreview,
  PagesSettings,
  PagesTemplates,
  PagesTemplatesCreate,
  PagesTemplatesPreview,
  PagesUsers,
  PagesUsersPreview,
} from '../../pages';

export const RoutesAuth = () => {
  return (
    <Routes>
      <Route
        path={routerPaths.dashboard}
        element={<PagesDashboard />}
      />
      <Route
        path={routerPaths.clients.index}
        element={<Outlet />}
      >
        <Route
          index={true}
          element={<PagesClients />}
        />
        <Route
          path={routerPaths.clients.create}
          element={<PagesClientsCreate />}
        />
        <Route
          path={routerPaths.clients.preview}
          element={<PagesClientsPreview />}
        />
      </Route>
      <Route
        path={routerPaths.templates.index}
        element={<Outlet />}
      >
        <Route
          index={true}
          element={<PagesTemplates />}
        />
        <Route
          path={routerPaths.templates.create}
          element={<PagesTemplatesCreate />}
        />
        <Route
          path={routerPaths.templates.preview}
          element={<PagesTemplatesPreview />}
        />
      </Route>
      <Route
        path={routerPaths.schemas.index}
        element={<Outlet />}
      >
        <Route
          index={true}
          path={routerPaths.schemas.index}
          element={<PagesSchemas />}
        />
        <Route
          path={routerPaths.schemas.create}
          element={<PagesSchemasCreate />}
        />
        <Route
          path={routerPaths.schemas.preview}
          element={<PagesSchemasPreview />}
        />
      </Route>
      <Route
        path={routerPaths.classifiers}
        element={<PagesClassifiers />}
      />
      <Route
        path={routerPaths.users.index}
        element={<Outlet />}
      >
        <Route
          index={true}
          path={routerPaths.users.index}
          element={<PagesUsers />}
        />
        <Route
          path={routerPaths.users.preview}
          element={<PagesUsersPreview />}
        />
      </Route>
      <Route
        path={routerPaths.settings}
        element={<PagesSettings />}
      />
      <Route
        path={routerPaths.permissions}
        element={<PagesPermissions />}
      />
      <Route
        path="*"
        element={<PagesDashboard />}
      />
    </Routes>
  );
};
