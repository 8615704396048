export const getTemplateClassifiers = (htmlString: string, cleanDuplicates: boolean = true) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const classifierSpans = doc.querySelectorAll('span.classifier');

  const classifiers = Array.from(classifierSpans).map((span) => ({
    classifier: span.getAttribute('data-classifier') || '',
    label: span.getAttribute('data-label') || '',
  }));

  if (cleanDuplicates) {
    const uniqueClassifiers = new Map<string, string>();

    classifiers.forEach(({ classifier, label }) => {
      if (!uniqueClassifiers.has(classifier)) {
        uniqueClassifiers.set(classifier, label);
      }
    });

    return Array.from(uniqueClassifiers, ([classifier, label]) => ({
      classifier,
      label,
    }));
  }

  return classifiers;
};
