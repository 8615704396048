import { Layout, theme } from 'antd';
import { ReactElement, useState } from 'react';

import { LayoutHeader, LayoutLogo, LayoutMenu } from '../../components';

interface IProps {
  children: ReactElement;
}

export const LayoutAuthMain = ({ children }: IProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider
        width={240}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ padding: 16, backgroundColor: token.colorBgContainer }}
      >
        <LayoutLogo collapsed={collapsed} />
        <LayoutMenu />
      </Layout.Sider>
      <Layout>
        <LayoutHeader
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Layout.Content style={{ margin: 16 }}>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
};
