import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row } from 'antd';
import 'ckeditor5/ckeditor5.css';

import { styles } from 'src/consts';

import { CKEditorCustom } from '../../components';

export const PagesTemplatesCreate = () => {
  return (
    <Row
      gutter={styles.gutter.default}
      className="h-full"
      align="stretch"
      wrap={false}
    >
      <Col flex="auto">
        <Card className="mb-4">
          <Input
            prefix={<EditOutlined />}
            size="large"
            variant="borderless"
            placeholder="Template title"
            className="font-semibold"
          />
        </Card>
        <Card>
          <div className="main-container">
            <CKEditorCustom
              initialData={
                '<p>asd asdasd asd wsretywer5n yhdfgsg</p><p>asd asd asd sd asasd asd asd sd as</p><p>&nbsp;asd sd asd<span class="classifier" data-classifier="business.address.municipality" data-label="Business > Address > Municipality"></span>a sda sdasd  asdas d12312 3as</p>'
              }
            />
          </div>
        </Card>
      </Col>
      <Col style={{ width: 320, minWidth: 320 }}>
        <Card className="h-full">
          <div>Title</div>
          <div>Description</div>
          <div>Sign</div>
          <Button
            type="primary"
            block={true}
          >
            Submit
          </Button>
        </Card>
      </Col>
    </Row>
  );
};
