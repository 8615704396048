import { EnumClientTypes } from '../enums';

export const mockClients = [
  {
    id: 'f0e58338-172e-4ca1-bd97-68b1d529aa06',
    name: 'Wilson',
    surname: 'Hauck',
    address: 'Warren, 34649 Padberg Loop, MI',
    type: EnumClientTypes.PCR,
    projects: 97,
    projectsActive: 100,
    registrationDate: '2024-11-01',
  },
  {
    id: 'f1cede23-3db7-4dcd-affc-87f7b9a91ff7',
    title: 'Reichert - Schaden',
    address: 'Corkeryfort, 41945 Lilly Meadow, MS',
    type: EnumClientTypes.BCR,
    projects: 43,
    projectsActive: 78,
    registrationDate: '2044-11-21',
  },
];
