interface IObject {
  [key: string]: any;
}

export const getNestedObject = <T>(
  object: IObject,
  path: string
): T | undefined => {
  return path.split('.').reduce((acc, part) => acc && acc[part], object) as T;
};
